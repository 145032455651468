



































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import ColorPicker from "@/components/Input/ColorPicker.vue";
import { Notification } from "@/models/notification.interface";
import { SystemError } from "@/models/system-error.interface";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: { PageTitle, Loader, Empty, ColorPicker },
})
export default class SystemStyles extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  loader = false;
  loading = false;
  mode = "LIGHT";

  system: {
    styles: {
      colors: {
        light: { value: string; level: string }[];
        dark: { value: string; level: string }[];
      };
    };
  } = {
    styles: {
      colors: {
        light: [],
        dark: [],
      },
    },
  };

  private async getStyles() {
    await this.$store.dispatch("styles/getStyles").catch((error) => {
      const Error: any = {
        date: this.currentDate(),
        path: this.$route.fullPath,
        errorTraceback: error.stack,
        errorMessage: error.message,
        criticalError: true,
        description: this.$tc("Errors.App.stylesError"),
      };
      this.$store.dispatch("notifications/setShowNotification", false);
      this.$store.dispatch("notifications/showSystemError", Error);
    });
  }

  private async updateStyles() {
    this.loading = true;
    await this.setStyles();
    this.loading = false;
  }

  private async created(): Promise<void> {
    await this.getStyles();
    await this.getColors();
    await this.setMode();
  }

  private async getColors() {
    const storedColors = await this.$store.getters["styles/getColors"];
    if (storedColors.length) {
      storedColors.forEach((color: any) => {
        if (color.mode == "LIGHT") {
          this.system.styles.colors.light.push({
            value: color.value,
            level: color.level,
          });
        } else {
          this.system.styles.colors.dark.push({
            value: color.value,
            level: color.level,
          });
        }
      });
    }
  }

  @Watch("functionalities")
  private async setMode() {
    this.functionalities.forEach((functionality) => {
      if (
        functionality.name == this.$constants.FUNCTIONALITY.DARK_MODE &&
        functionality.status.name == this.$constants.STATUS.ACTIVE
      ) {
        this.mode = "DARK";
        this.$vuetify.theme.dark = true;
        this.$store.dispatch("styles/setTheme", true);
      } else if (
        functionality.name == this.$constants.FUNCTIONALITY.DARK_MODE &&
        functionality.status.name == this.$constants.STATUS.INACTIVE
      ) {
        this.mode = "LIGHT";
        this.$vuetify.theme.dark = false;
        this.$store.dispatch("styles/setTheme", false);
      }
    });
  }

  private setColor(color: any) {
    if (
      color.mode == "LIGHT" &&
      (color.level == "PRIMARY" || color.level == "SECONDARY")
    ) {
      this.$vuetify.theme.themes.light[color.level.toLowerCase()] = color.value;
    }
  }

  private async setStyles() {
    const payloadStyles: any[] = [];
    this.system.styles.colors.light.forEach((style) => {
      payloadStyles.push({
        value: style.value,
        level: style.level,
        mode: "LIGHT",
        type: "COLOR",
      });
    });
    this.system.styles.colors.dark.forEach((style) => {
      payloadStyles.push({
        value: style.value,
        level: style.level,
        mode: "DARK",
        type: "COLOR",
      });
    });

    await this.$store
      .dispatch("styles/setStyles", { styles: payloadStyles })
      .then(async () => {
        const storedColors = await this.$store.getters["styles/getColors"];
        if (storedColors.length) {
          storedColors.forEach((color: any) => {
            this.setColor(color);
          });
        }
      })
      .catch((error) => {
        const Error: SystemError = {
          date: this.currentDate(),
          path: this.$route.fullPath,
          errorTraceback: error.stack,
          errorMessage: error.message,
          criticalError: false,
          description: this.$tc("Styles.fetchError.colors"),
        };

        this.$store.dispatch("notifications/showSystemError", Error);
      })
      .finally(async () => {
        await this.updateMode();
      });
  }

  private async updateMode() {
    let payloadMode: { status: string; name: string; id: number } = {
      status: "",
      name: this.$constants.FUNCTIONALITY.DARK_MODE,
      id: 0,
    };

    const functionalities = await this.$store.getters[
      "functionalities/getFunctionalities"
    ];

    functionalities.forEach((functionality: any) => {
      if (functionality.name == this.$constants.FUNCTIONALITY.DARK_MODE) {
        payloadMode.id = functionality.id;
      }
    });

    if (this.mode == "LIGHT") {
      payloadMode.status = this.$constants.STATUS.INACTIVE;
    } else {
      payloadMode.status = this.$constants.STATUS.ACTIVE;
    }

    await this.$store
      .dispatch("functionalities/setFunctionalityStatus", payloadMode)
      .then(async () => {
        const notification: Notification = {
          message: this.$tc("Styles.success"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
        };

        this.$store.dispatch("notifications/showNotification", notification);
      })
      .catch((error) => {
        const Error: SystemError = {
          date: this.currentDate(),
          path: this.$route.fullPath,
          errorTraceback: error.stack,
          errorMessage: error.message,
          criticalError: false,
          description: this.$tc("Styles.fetchError.mode"),
        };

        this.$store.dispatch("notifications/showSystemError", Error);
      });
  }

  private get functionalities(): any[] {
    return this.$store.getters["functionalities/getFunctionalities"];
  }

  private get availableColors(): any[] {
    const colors = this.system.styles.colors.light;

    const available = colors.filter((color) => {
      if (color.level == "PRIMARY" || color.level == "SECONDARY") return color;
    });

    return available;
  }
}
